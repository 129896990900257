









































import { Component, Prop, Vue } from 'vue-property-decorator';
import { getLabLogScriptData, getLabLogScripts } from '@/api/labs';
import VueMarkdown from 'vue-markdown-render';
import { LabHistoryModule } from '@/store/modules/labHistoryState';
import { IScriptLog } from '@/models/users';
import { decodeASCII } from '@/utils/Lab';

Vue.filter('formatDate', function(value) {
  if (value) {
    return new Date(String(value)).toLocaleString();
  }
});

@Component({
  name: 'LabScriptsHistory',
  components: {
    VueMarkdown
  }
})
export default class extends Vue {
  private logsLoading = true;
  @Prop() lab!: number;
  private selectedLog = null;
  private total = 0;

  get logs() {
    return LabHistoryModule.LabHistory as IScriptLog[];
  }

  mounted() {
    this.logsLoading = true;
    this.logsPagination(1);
  }

  logsPagination(page: number) {
    this.selectedLog = null;

    getLabLogScripts({ labID: this.lab, page })
      .then(response => {
        if (response?.data) {
          this.total = response.data.totalPages * 10;
          LabHistoryModule.SetHistory(response.data.logs || []);
        }
      })
      .finally(() => {
        this.logsLoading = false;
      });
  }

  selectLog(index: number) {
    if (typeof index === 'number' && !this.logs[index].log) {
      getLabLogScriptData({
        logID: this.logs[index].id
      })
        .then(response => {
          LabHistoryModule.SetHistoryLog(
            response?.data
              ? {
                  id: this.logs[index].id,
                  log: decodeASCII(response.data)
                }
              : {
                  id: this.logs[index].id,
                  log: '\n The log is empty'
                }
          );
        })
        .catch(e => console.error('Error:', e));
    }
  }
}

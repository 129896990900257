








































































































































































































































































































































































































































































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import Breadcrumbs from '@/components/breadcrumbs/Index.vue';
import { IChallenge } from '@/models/users';
import { Difficulty, ExecType } from '@/models/users.enums';
import { CoursesModule } from '@/store/modules/coursesState';
import { ElForm } from 'element-ui/types/form';
import VueMarkdown from 'vue-markdown-render';
import fullscreen from 'vue-fullscreen';
import LabScriptsHistory from './LabScriptsHistory.vue';
import VueJS from 'vue';
VueJS.use(fullscreen);

enum SHELLS {
  BASH = '/bin/bash',
  SH = '/bin/sh'
}

@Component({
  name: 'Challenges',
  components: {
    Breadcrumbs,
    VueMarkdown,
    LabScriptsHistory
  },
  directives: {
    focus: {
      inserted(el) {
        el?.focus();
      }
    }
  }
})
export default class extends Vue {
  readonly ExecType = ExecType;
  readonly Difficulty = Difficulty;
  readonly SHELLS = SHELLS;
  // @ts-expect-error
  challenge!: IChallenge = {
    execEnv: ExecType.CONTAINER,
    containerImage: 'apline',
    containerShell: SHELLS.BASH,
    EC2AMIImage: 'ami-05394aef61908afaa',
    EC2Type: 't3.micro'
  };
  formChallenge = {};
  kubernetesVersions: { label: string; value: string }[] = [];

  private rules = {
    containerImage: [
      { required: true, message: 'container image is required' }
    ],
    EC2AMIImage: [{ required: true, message: 'EC2 AMI is required' }],
    EC2Type: [{ required: true, message: 'EC2 type is required' }],
    'k8s.master.EC2Type': [{ required: true, message: 'EC2 type is required' }],
    'k8s.workers.EC2Type': [{ required: true, message: 'EC2 type is required' }]
  };
  private formInvalid = false;
  private activeTab?: string = 'tasks';
  private labID = +this.$router.currentRoute.params.id;
  private tabLabName = '';
  showTitleInput = null;
  showHintInput = null;
  create = false;

  private challengeLoading = true;
  private saveLoading = false;

  private showLabWasSaved = false;

  @Ref()
  private fullscreen;

  async mounted() {
    Promise.all([
      await CoursesModule.GetChallenge(this.labID),
      await CoursesModule.GetKubernetesVersions()
    ]);

    if (CoursesModule.challenges && CoursesModule.kubernetesVersions) {
      this.challenge = { ...CoursesModule.challenges };
      this.challengeLoading = false;
      this.tabLabName =
        this.challenge.courseName + ' / ' + this.challenge.labName;
      const title = 'Challenges :: ' + this.tabLabName;
      document.title = title;

      if (!this.challenge.difficulty) {
        this.challenge.difficulty = this.Difficulty.EASY;
      }

      this.makeKubernetesVersions();
    } else {
      this.$router.back();
    }
  }

  private showInput(id, type) {
    if (type === 'title') {
      this.showTitleInput = id;
    } else if (type === 'hint') {
      this.showHintInput = id;
    }
  }

  private hideInput(type) {
    if (type === 'title') {
      this.showTitleInput = null;
    } else if (type === 'hint') {
      this.showHintInput = null;
    }
  }

  get getButtonTitle(): string {
    return 'Save challenge';
  }

  get labName(): string {
    return this.challenge?.labName ?? '';
  }

  private deleteTask(node) {
    const deleteChallenge = this.challenge.questions.findIndex(
      c => c.$treeNodeId === node.id
    );
    this.challenge.questions.splice(deleteChallenge, 1);
  }

  click(e) {
    console.log(e);
  }

  drop(draggingNode, dropNode, type) {
    if (type === 'inner') {
      return false;
    }

    return true;
  }

  private makeKubernetesVersions() {
    const versions = CoursesModule.kubernetesVersions;

    for (const key in versions) {
      this.kubernetesVersions.push({
        label: versions[key],
        value: key
      });
    }

    this.kubernetesVersions.sort((a, b) => {
      if (a.label === b.label) return 0;

      return b.label > a.label ? 1 : -1;
    });
  }

  private addTasks() {
    const newTask = {
      title: 'Task name',
      hint: 'Task hint',
      waitSuccess: false,
      taskID: ''
    };
    if (!this.challenge.questions) {
      this.challenge.questions = [];
    }
    this.challenge.questions.push(newTask);
  }

  private async saveTasks() {
    try {
      this.saveLoading = true;
      this.challenge.labID = this.labID;
      this.challenge.containerImage = this.challenge.containerImage.trim();

      if (this.create) {
        await CoursesModule.CreateChallenge(this.challenge);
      } else {
        await CoursesModule.UpdateChallenge(this.challenge);
      }
      this.showLabWasSaved = true;
    } finally {
      this.saveLoading = false;
    }
  }

  @Watch('challenge', { deep: true })
  private validateForm() {
    this.challenge?.questions?.forEach((el, index) => {
      el.index = index + 1;
    });

    this.$nextTick(() => {
      (this.$refs.challengeForm as ElForm).validate((valid: boolean) => {
        this.formInvalid = !valid;
      });
    });
  }

  private fullScreenChange() {
    this.fullscreen?.toggle();
  }
}

export const validEmail = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/;
  return !re.test(String(email).toLowerCase());
};

export const validVimeoUrl = (url: string): boolean => {
  const re = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i;
  return !re.test(String(url).toLowerCase());
};

export const validTotalTime = (time: string): boolean => {
  const re = /(^[0-9]{1,5}[h]{1})?( )?[0-9]{1,5}[m]{1}$/;
  // /^(?=\d)((\d+)(h|:))? *((\d+)m?)?$/;
  // /^[0-9]\d[h]{1} [0-9]\d[m]{1}$/
  //const re = /^([0-9][0-9]*h?\s)?([0-9][0-9]*m?\s?)$/;
  return !re.exec(String(time).toLowerCase());
};
















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import ProgressBar from '@/components/progressBar/Index.vue';
import Breadcrumbs from '@/components/breadcrumbs/Index.vue';
import EditCourse from '@/components/dialogs/editCourse/Index.vue';
import EditLab from '@/components/dialogs/editLab/Index.vue';
import { ICourseInfo, IGroup, ILabInfo } from '@/models/users';
import { CoursesModule } from '@/store/modules/coursesState';
import { Tree } from 'element-ui';
import { DialogModule } from '@/store/modules/dialogState';
import { GroupsModule } from '@/store/modules/groupsState';
import { BLANK_COURSE, BLANK_LAB } from '@/models';
import AddedCourse from '@/components/dialogs/addedCourse/Index.vue';
import AddedLab from '@/components/dialogs/addedLab/Index.vue';
import { timeoutPromise } from '@/utils/timeoutPromise';

@Component({
  name: 'Courses',
  components: {
    ProgressBar,
    Breadcrumbs,
    EditCourse,
    EditLab,
    AddedCourse,
    AddedLab
  }
})
export default class extends Vue {
  private defaultProps = {
    children: 'labs',
    label: 'title',
    percentage: 'percentage'
  };
  //courses: ICourseInfo[] = [];
  search = '';
  showDialogGroups = false;
  loadingDialogGroups = false;
  editedCourseGroups: ICourseInfo | null = null;
  editedLabGroups: ILabInfo | null = null;
  loading = false;
  groups: IGroup[] = [];
  checkedGroups: number[] = [];
  typeNode: 'course' | 'lab' = 'course';
  selectedGroups: {
    access: true;
    expireDate: 'string';
    groupID: 0;
    permissionDate: 'string';
  }[] = [];
  expandedKeys: number[] = [];

  private coursesLoading = true;
  private groupsLoading = true;

  @Watch('search')
  filterText(val: string) {
    (this.$refs.tree as Tree).filter(val);
  }

  private filterNode(value: string, data: ILabInfo | ICourseInfo) {
    if (!value) return true;
    return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }

  get checkedGroup() {
    return CoursesModule.checkedGroups;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  expandTree(data: ICourseInfo, node, self) {
    if (!data.expandID) {
      return;
    }
    this.expandedKeys.push(data.expandID);
  }
  collapseTree(data) {
    const num = this.expandedKeys.findIndex(c => c === data.expandID);
    this.expandedKeys.splice(num, 1);
  }

  get selectedCourse() {
    const selected = CoursesModule.selectedCourse;
    if (!selected) {
      return null;
    }
    return Object.assign({}, selected);
  }

  get selectedLab() {
    return CoursesModule.selectedLab;
  }

  get showEditDialog() {
    return DialogModule.showEdit;
  }
  set showEditDialog(data: boolean) {
    DialogModule.toggleEdit();
  }

  get editing() {
    return DialogModule.editing;
  }

  get showCreateDialog() {
    return DialogModule.showCreate;
  }
  set showCreateDialog(data: boolean) {
    DialogModule.toggleCreate();
  }

  get courses() {
    return CoursesModule.courses.map(course => {
      course.expandID = course.id;
      return course;
    });
  }

  mounted() {
    // this.userID = Number(this.$router.currentRoute.params.id);
    CoursesModule.GetCourses().finally(() => {
      this.coursesLoading = false;
    });
    GroupsModule.GetGroups()
      .then(() => {
        this.groups = GroupsModule.groups;
      })
      .finally(() => {
        this.groupsLoading = false;
      });
  }

  private addCourse() {
    CoursesModule.SetSelectedCourse(BLANK_COURSE());
    DialogModule.toggleCreate();
  }

  private addLab() {
    CoursesModule.GetCourses(); /* .then(() => {
      this.courses = CoursesModule.courses;
    }); */
    CoursesModule.SetSelectedLab(BLANK_LAB());
    DialogModule.toggleCreate();
  }

  private editCourse(node: ICourseInfo) {
    DialogModule.toggleEdit();
    CoursesModule.SetSelectedCourse(node);
  }

  private editLab(node: ILabInfo) {
    DialogModule.toggleEdit();
    CoursesModule.CurrentLab(node.id);
  }

  private selectGroupsCourse(node: ICourseInfo) {
    const cg: number[] = [];
    this.checkedGroups = [];
    this.loadingDialogGroups = true;

    const checkGroups = CoursesModule.GetGroupsForCourse({ courseID: node.id });
    checkGroups
      .then(async (data: any) => {
        Array.from(data).forEach((el: any) => {
          if (el.access) {
            cg.push(el.groupID);
          }
        });
        this.checkedGroups = cg;
        await this.$nextTick();
      })
      .catch(e => console.error(e))
      .then(() => {
        setTimeout(() => {
          this.loadingDialogGroups = false;
        }, 50);
      });

    this.$nextTick(() => {
      this.editedCourseGroups = node;
      this.showDialogGroups = true;
    });
  }

  private selectGroupsLab(node: ILabInfo) {
    const cg: number[] = [];
    this.checkedGroups = [];
    this.loadingDialogGroups = true;
    //this.checkedGroups = node.groups?.length !== 0 ? node.groups : [];
    const checkGroups = CoursesModule.GetGroupsForLabs(node.id);

    //const checkGroups = CoursesModule.GetGroupsForCourse({ courseID: node.id });
    checkGroups
      .then(async (data: any) => {
        Array.from(data).forEach((el: any) => {
          if (el.access) {
            cg.push(el.groupID);
          }
        });
        this.checkedGroups = cg;
        await this.$nextTick();
      })
      .catch(e => console.error(e))
      .then(() => {
        setTimeout(() => {
          this.loadingDialogGroups = false;
        }, 50);
      });

    this.$nextTick(() => {
      this.editedLabGroups = node;
      this.showDialogGroups = true;
    });
  }

  private editChallenge(node: ILabInfo) {
    this.$router.push({
      name: 'Challenges',
      params: { id: node.id.toString() }
    });
  }

  private getDialogTitle(): string {
    let title = '';
    title = this.editing ? 'Edit Course' : 'Create Course';
    return title;
  }

  private getDialogTitleLab(): string {
    let title = '';
    title = this.editing ? 'Edit Lab' : 'Create Lab';
    return title;
  }

  private closeDialog() {
    DialogModule.closeCreate();
    CoursesModule.SetSelectedCourse(null);
    CoursesModule.SetSelectedLab(null);
  }

  closeDialogGroup() {
    this.showDialogGroups = false;
    this.editedCourseGroups = null;
    this.editedLabGroups = null;
  }
  private async updateGroups() {
    try {
      this.loading = true;
      if (this.editedCourseGroups) {
        this.selectedGroups = [];

        this.checkedGroups.forEach(groupID => {
          if (CoursesModule.groupsForCourse) {
            CoursesModule.groupsForCourse.forEach(group => {
              if (groupID === group.groupID) {
                group.access = true;
                this.selectedGroups.push(group);
              }
            });
          }
        });
        this.editedCourseGroups.accessGroups = [...this.selectedGroups];

        if (this.editedCourseGroups.labs) {
          this.editedCourseGroups.labs.forEach(lab => {
            lab.accessGroups = { ...lab.accessGroups, ...this.selectedGroups };
          });
        }
        CoursesModule.UpdateGroupsForCourse({
          accessGroups: this.editedCourseGroups.accessGroups,
          courseID: this.editedCourseGroups.id
        });
        await CoursesModule.EditCourse(this.editedCourseGroups);
      }

      if (this.editedLabGroups) {
        this.selectedGroups = [];

        this.checkedGroups.forEach(groupID => {
          if (CoursesModule.groupsForLab) {
            CoursesModule.groupsForLab.forEach(group => {
              if (groupID === group.groupID) {
                group.access = true;
                this.selectedGroups.push(group);
              }
            });
          }
        });

        this.editedLabGroups.accessGroups = [...this.checkedGroups];
        //CoursesModule.EditLab(this.editedLabGroups);
        this.editedLabGroups.accessGroups = [...this.selectedGroups];

        CoursesModule.UpdateGroupsForLab({
          accessGroups: this.editedLabGroups.accessGroups,
          labID: this.editedLabGroups.id
        });
        await CoursesModule.EditLab(this.editedLabGroups);
      }
      await this.$nextTick();
      await timeoutPromise(500);
    } finally {
      this.loading = false;
      this.editedCourseGroups = null;
      this.editedLabGroups = null;
      this.closeDialog();
    }
  }
}

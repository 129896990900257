
















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { validEmail } from '@/utils/validate';
import { ElForm } from 'element-ui/types/form';
import { IGroup, IUser } from '@/models/users';
import { UsersModule } from '@/store/modules/usersState';
import { DialogModule } from '@/store/modules/dialogState';
import { GroupsModule } from '@/store/modules/groupsState';

@Component({
  name: 'EditUser',
  components: {}
})
export default class extends Vue {
  @Prop({ required: true }) private userForm!: IUser;
  private groups: IGroup[] = [];
  private loading = false;
  private validForm = false;
  private passRegExp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*().,/?:;])([a-zA-Z0-9!@#$%^&*().,/?:;]+){6,}$/;

  get editing() {
    return DialogModule.editing;
  }

  mounted() {
    GroupsModule.GetGroups().then(() => {
      this.groups = GroupsModule.groups;
    });
  }

  private userRules = {
    email: [
      {
        required: true,
        validator: (rule: string, value: string, callback: Function) => {
          validEmail(value)
            ? callback(new Error('Please enter the correct email'))
            : callback();
        },
        trigger: 'blur'
      }
    ],
    password: [
      {
        validator: (rule: string, value: string, callback: Function) => {
          (!this.editing || (this.editing && this.userForm.password)) &&
          !this.passRegExp.test(this.userForm.password as string)
            ? callback(
                new Error(
                  'Password must be at least 6 characters long, must contain at least one uppercase, one lowercase letter, a number, and a symbol "!@#$%^&*().,/?:;".'
                )
              )
            : callback();
        },
        trigger: 'blur'
      }
    ],
    userName: [
      {
        required: true,
        min: 2,
        message: 'The username can not be less than 2 characters',
        trigger: 'blur'
      },
      {
        required: true,
        max: 50,
        message: 'The username exceeds 50 characters',
        trigger: 'blur'
      }
    ]
  };

  private saveUser() {
    (this.$refs.userForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) return false;

      try {
        if (this.editing) {
          await UsersModule.EditUser({ ...this.userForm, asAdmin: true });
        } else {
          const isSuccessfully = await UsersModule.CreateUser(this.userForm);
          await this.$nextTick();

          if (isSuccessfully) {
            await DialogModule.toggleNewUserAdded(this.userForm);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
        this.closeDialog();
      }
    });
  }

  private closeDialog() {
    DialogModule.closeCreate();
  }

  private generatePassword() {
    do {
      this.userForm.password = '';

      for (let i = 0; i < 10; i++) {
        this.userForm.password += 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*().,/?:;'.charAt(
          Math.floor(Math.random() * 78)
        );
      }
    } while (!this.passRegExp.test(this.userForm.password));
  }

  @Watch('userForm', { deep: true })
  private validateForm() {
    const form = this.$refs.userForm as ElForm;

    if (this.userForm.email === form?.model['email']) {
      form.validate((valid: boolean) => (this.validForm = valid));
    }
  }
}

































































































































import { Component, Vue } from 'vue-property-decorator';
import { UsersModule } from '../../store/modules/usersState';
import { IGroup, IUser } from '@/models/users';
import ProgressBar from '@/components/progressBar/Index.vue';
import Breadcrumbs from '@/components/breadcrumbs/Index.vue';
import EditUser from '@/components/dialogs/editUser/Index.vue';
import { DialogModule } from '@/store/modules/dialogState';
import { GroupsModule } from '@/store/modules/groupsState';
import { BLANK_USER } from '@/models';

@Component({
  name: 'Users',
  components: {
    ProgressBar,
    Breadcrumbs,
    EditUser
  }
})
export default class extends Vue {
  private search = '';
  private currentPage = 1;
  private edited: IUser | null = null;
  private groups: IGroup[] = [];
  private sort: { prop: string; order: string } = {
    prop: 'id',
    order: 'ascending'
  };

  private usersLoading = true;
  private groupsLoading = true;

  get users() {
    return UsersModule.users;
  }

  mounted() {
    UsersModule.GetUsers().finally(() => {
      this.usersLoading = false;
    });
    GroupsModule.GetGroups()
      .then(() => {
        this.groups = GroupsModule.groups;
      })
      .finally(() => {
        this.groupsLoading = false;
      });
  }

  get editing() {
    return DialogModule.editing;
  }

  get showEditDialog() {
    return DialogModule.showEdit;
  }

  get showCreateDialog() {
    return DialogModule.showCreate;
  }

  public changePagination(page: number) {
    this.currentPage = page;
  }

  get showUserAddedDialog() {
    return DialogModule.showNewUserAdded;
  }

  get addedUserName(): string {
    return DialogModule.newUserModel.userName;
  }

  private filter(users: IUser[]): IUser[] {
    return users
      .filter(
        (data: IUser) =>
          !this.search ||
          data.userName.toLowerCase().includes(this.search.toLowerCase())
      )
      .slice((this.currentPage - 1) * 10, this.currentPage * 10);
  }

  private editUser(user: IUser) {
    UsersModule.SetEditedUser(user);
    DialogModule.toggleEdit();
    this.edited = { ...user, password: '' };
  }

  private deleteUser(user: IUser) {
    this.$confirm('Are you sure you want to delete this user?', 'Delete User', {
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      type: 'error'
    }).then(() => {
      UsersModule.DeleteUser(user);
    });
  }

  private showDetails(user: IUser) {
    this.$router.push({ name: 'Stats', params: { id: user.id.toString() } });
  }

  getGroupName(groupID: number): string {
    const group = this.groups.find(group => group.id === groupID);
    return group ? group.name : '';
  }

  private addUser() {
    UsersModule.SetEditedUser(BLANK_USER());
    DialogModule.toggleCreate();
    this.edited = { ...BLANK_USER() };
  }

  private closeDialog() {
    DialogModule.closeCreate();
    UsersModule.sortUsers(this.sort);
  }

  private closeUserAddedDialogHandler() {
    DialogModule.toggleNewUserAdded();
  }

  private getDialogTitle(): string {
    let title = '';
    title = this.editing ? 'Edit User' : 'Create User';
    return title;
  }

  private changeSort(e: { prop: string; order: string }) {
    this.sort = { prop: e.prop, order: e.order };
    UsersModule.sortUsers({ prop: e.prop, order: e.order });
  }

  private mailSort(firstEl, secondEl) {
    const nameA = firstEl.email.toUpperCase();
    const nameB = secondEl.email.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  private tutorSort(firstEl, secondEl) {
    const nameA = firstEl.isAdmin;
    const nameB = secondEl.isAdmin;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  private nameSort(firstEl, secondEl) {
    const nameA = firstEl.userName.replace(/\s/g, '').toLowerCase();
    const nameB = secondEl.userName.replace(/\s/g, '').toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
  private idSort(firstEl, secondEl) {
    const nameA = firstEl.id;
    const nameB = secondEl.id;

    return nameA - nameB;
  }

  private groupSort(firstEl, secondEl) {
    const nameA = firstEl.groupID;
    const nameB = secondEl.groupID;
    return nameA - nameB;
  }
}

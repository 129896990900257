


































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ElForm} from 'element-ui/types/form';
import {DialogModule} from '@/store/modules/dialogState';
import {ICourseInfo, ILabInfo} from '@/models/users';
import {CoursesModule} from '@/store/modules/coursesState';
import {validVimeoUrl} from '@/utils/validate';

@Component({
  name: 'EditLab',
  components: {},
})
export default class extends Vue {
  @Prop({required: true}) private labForm!: ILabInfo;
  @Prop({required: true}) private courses!: ICourseInfo[];
  @Prop({required: true}) private editing!: boolean;

  private loading = false;
  private validForm = false;

  private validateText(rule: string, value: string, callback: Function) {
    if (value.trim().length < 6) {
      callback(new Error('The field can not be less than 6 characters'));
    } else if (value.length > 256) {
      callback(new Error('The field can not be more than 256 characters'));
    } else {
      callback();
    }
  }

  private validateLimitText(rule: string, value: string, callback: Function) {
    if (value?.length > 256) {
      callback(new Error('The field can not be more than 256 characters'));
    } else {
      callback();
    }
  }

  private validateTextTrim(rule: string, value: string, callback: Function) {
    if (value.trim().length === 0) {
      callback(new Error('The field can not be empty'));
    } else if (value.length > 256) {
      callback(new Error('The field can not be more than 256 characters'));
    } else {
      callback();
    }
  }

  private validateVideoUrl(rule: string, value: string, callback: Function) {
    if (value) {
      if (validVimeoUrl(value)) {
        callback(
          new Error(
            'Wrong url format. Valid format: https://vimeo.com/{videoID}'
          )
        );
      } else {
        callback();
      }
    } else if (!value) {
      callback();
    }
  }

  private rules = {
    name: [{validator: this.validateTextTrim, trigger: 'blur'}],
    description: [{validator: this.validateText, trigger: 'blur'}],
    videoUrl: [
      {validator: this.validateVideoUrl, trigger: 'blur'},
      {validator: this.validateLimitText, trigger: 'blur'},
    ],
    /* laboratoryToken: [{ validator: this.validateText, trigger: 'blur' }], */
  };

  private save() {
    (this.$refs.labForm as ElForm).validate(async (valid: boolean) => {
      if (valid) {
        try {
          this.loading = true;
          if (this.editing) {
            await CoursesModule.EditLab(this.labForm);
          } else {
            await CoursesModule.CreateLab(this.labForm);
            await this.$nextTick();
            await DialogModule.toggleNewLabAdded(this.labForm);
          }
        } finally {
          this.loading = false;
          this.closeDialog();
        }
      } else {
        return false;
      }
    });
  }

  private closeDialog() {
    DialogModule.closeCreate();
    CoursesModule.ClearSelectedLab();

    this.labForm.id = 0;
    this.labForm.courseID = null;
    this.labForm.videoUrl = '';
    this.labForm.laboratoryToken = '';
    this.labForm.description = '';
    this.labForm.accessGroups = [];
    this.labForm.name = '';
  }

  private deleteLab() {
    this.$confirm('Are you sure you want to delete this lab?', 'Delete Lab', {
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      type: 'error',
    }).then(() => {
      CoursesModule.DeleteLab(this.labForm);
      this.closeDialog();
    });
  }

  @Watch('labForm', {deep: true})
  private validateForm() {
    (this.$refs.labForm as ElForm).validate((valid: boolean) => {
      this.validForm = valid;
    });
  }
}

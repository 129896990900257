










import { Component, Vue } from 'vue-property-decorator'
import TopBar from '@/components/layout/topBar/Index.vue';
import LeftBar from '@/components/layout/leftBar/Index.vue';

@Component({
  name: 'Layout',
  components: {
    TopBar,
    LeftBar,
  }
})

export default class extends Vue {
  get key() {
    return this.$route.path
  }
}


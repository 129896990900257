
































































































































import { Component, Vue } from 'vue-property-decorator';
import { IDashboardUser, ICourse, IUserCourse, IGroup } from '@/models/users';
import ProgressBar from '@/components/progressBar/Index.vue';
import Breadcrumbs from '@/components/breadcrumbs/Index.vue';
import { DashboardModule } from '@/store/modules/dashboardState';
import { GroupsModule } from '@/store/modules/groupsState';

@Component({
  name: 'Dashboard',
  components: {
    ProgressBar,
    Breadcrumbs
  }
})
export default class extends Vue {
  private users: IDashboardUser[] = [];
  private courses: ICourse[] = [];
  private search = '';
  private currentPage = 1;
  private showLabs: { show: boolean; id: number }[] = [];
  private groups: IGroup[] = [];
  private valueSelectGroup = [];
  private visibleUsers: IDashboardUser[] = [];
  private pageSize = 10;
  private key = 1;

  private usersLoading = true;
  private groupsLoading = true;
  private courseLoading = false;

  handleSizeChange(val) {
    this.pageSize = val;
  }

  mounted() {
    DashboardModule.GetDashboardUsers()
      .then(() => {
        this.courses = DashboardModule.courses;
        this.users = this.initUsers(DashboardModule.users);

        this.visibleUsers = this.users;
        const _showLabs: { show: boolean; id: number }[] = [];
        for (const courseIndex in this.courses) {
          _showLabs.push({ show: false, id: +courseIndex });
        }

        this.showLabs = _showLabs;
      })
      .finally(() => {
        this.usersLoading = false;
      });

    GroupsModule.GetGroups()
      .then(() => {
        this.groups = GroupsModule.groups;
      })
      .finally(() => {
        this.groupsLoading = false;
      });
  }

  private initUsers(users) {
    users.forEach(user => {
      for (const courseID in this.courses) {
        const courseData = user.coursesProgress
          ? user.coursesProgress[courseID]
          : 0;
        user['c_' + courseID] = courseData;
      }
    });

    return users;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private initLabs(courseIndex: number) {
    const progresses = {};

    for (const labID in DashboardModule.labsInfo) {
      const value: any = DashboardModule.labsInfo[labID];
      progresses[value.userID] = value.labsProgress;
    }

    this.users.forEach(user => {
      const labs = progresses[user.userID];

      for (const labID in labs) {
        user['l_' + labID] = labs[labID];
      }
    });
    this.key++;
    return this.users;
  }

  private getCourseData(courseID: number, userCourses: IUserCourse[]): number {
    const currentCourse = userCourses.find(
      course => course.courseID === courseID
    );
    return currentCourse ? currentCourse.percentage : 0;
  }

  private async toggleCourse(courseIndex: number) {
    const index = this.showLabs.findIndex(item => item.id === +courseIndex);
    if (this.showLabs[index]) {
      this.courseLoading = true;
      try {
        if (!('labs' in this.courses[courseIndex])) {
          await DashboardModule.GetDashboardLabsInfo(courseIndex);
        }
        this.visibleUsers = this.initLabs(courseIndex);
        this.courses[courseIndex].labs = DashboardModule.labsTitle;
      } finally {
        this.courseLoading = false;
      }

      this.showLabs[index].show = !this.showLabs[index].show;
      this.key++;
    }
  }

  public changePagination(page: number) {
    this.currentPage = page;
  }

  changeValueSelectGroup() {
    let _visibleUsers: IDashboardUser[] = [];
    this.valueSelectGroup.forEach(group => {
      this.users
        .filter((users: IDashboardUser) => users.groupID === group)
        .forEach(item => _visibleUsers.push(item));
    });

    if (this.valueSelectGroup.length === 0) _visibleUsers = this.users;
    this.visibleUsers = _visibleUsers;
    return this.valueSelectGroup;
  }

  private filter(users: IDashboardUser[]): IDashboardUser[] {
    return users
      .filter(
        (data: IDashboardUser) =>
          !this.search ||
          data.userName.toLowerCase().includes(this.search.toLowerCase()) ||
          data.email.toLowerCase().includes(this.search.toLowerCase())
      )
      .slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
  }
}

















































import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/appState';

@Component({
  name: 'LeftBar',
  components: {},
})
export default class extends Vue {
  public menuSelect(index: string) {
    if (this.$router.currentRoute.name !== index) {
      this.$router
        .push({
          name: index,
        })
        .catch(() => {
          //
        });
    }
  }
  activeMenu() {
    const route = this.$route;
    const { meta, path } = route;
    // if set path, the sidebar will highlight the path you set
    if (meta.parent) {
      return meta.parent.title;
    }
    return meta.title;
  }

  get isCollapse() {
    return true;
    // return !this.sidebar.opened
  }

  private logout() {
    AppModule.Logout().then(() => {
      this.$router.push({ path: '/login' });
    });
  }
}

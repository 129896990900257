import request from '@/utils/request';
import { AxiosPromise } from 'axios';
import { IGroup } from '@/models/users';

interface ICreateGroup {
  name: string;
  description: string;
}
export const getGroups = (): AxiosPromise<IGroup[]> =>
  request({
    url: '/groups',
    method: 'get',
  });
export const createGroup = (data: ICreateGroup): AxiosPromise<IGroup> =>
  request({
    url: '/group',
    method: 'post',
    data,
  });
export const editGroup = (data: IGroup): AxiosPromise<number> =>
  request({
    url: '/group',
    method: 'put',
    data,
  });

export const deleteGroup = (groupID: number): AxiosPromise<{ data: boolean }> =>
  request({
    url: '/group',
    method: 'delete',
    data: { groupID: groupID },
  });

export const getGroupInfo = (data: { groupID: number }): AxiosPromise<IGroup> =>
  request({
    url: '/group/getGroupInfo',
    method: 'post',
    data,
  });

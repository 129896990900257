










































import { Component, Vue } from 'vue-property-decorator';
import { UsersModule } from '../../store/modules/usersState';
import ProgressBar from '@/components/progressBar/Index.vue';
import Breadcrumbs from '@/components/breadcrumbs/Index.vue';
import Logs from '@/components/logs/Index.vue';
import { ILab, IStat } from '@/models/users';
import store from '@/store';
import LabLog from '@/components/logs/LabLog.vue';

@Component({
  name: 'UserStats',
  components: {
    ProgressBar,
    Breadcrumbs,
    Logs,
    LabLog,
  },
})
export default class extends Vue {
  private defaultProps = {
    children: 'labs',
    label: 'title',
    percentage: 'percentage',
  };
  userID = 0;
  stats: IStat[] = [];
  showStatsDialog = false;
  selectedLab: ILab = {
    title: '',
    id: 0,
    logs: [],
    percentage: 0,
  };

  private statsLoading = true;

  get logs() {
    return this.$store.state.users.logs;
  }

  get totalLogs() {
    return this.$store.state.users.totalLogs;
  }

  mounted() {
    this.userID = Number(this.$router.currentRoute.params.id);
    UsersModule.GetStats(this.userID)
      .then(() => {
        this.stats = UsersModule.stats;
      })
      .finally(() => {
        this.statsLoading = false;
      });
  }

  getInfo(node: { data: ILab }) {
    this.selectedLab = node.data;
    this.showStatsDialog = true;

    this.changePage(1);
  }

  changePage(page: number) {
    store.dispatch('getUserLabsLog', {
      userID: this.userID,
      labId: this.selectedLab.id,
      page,
    });
  }
}

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('breadcrumbs'),_c('div',{staticClass:"table__search"},[_c('el-input',{attrs:{"placeholder":"Type to search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('el-select',{staticStyle:{"margin-left":"20px"},attrs:{"multiple":"","collapse-tags":"","placeholder":"Select groups"},on:{"change":_vm.changeValueSelectGroup},model:{value:(_vm.valueSelectGroup),callback:function ($$v) {_vm.valueSelectGroup=$$v},expression:"valueSelectGroup"}},_vm._l((_vm.groups),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.usersLoading || _vm.groupsLoading || _vm.courseLoading),expression:"usersLoading || groupsLoading || courseLoading"}],key:_vm.key,staticClass:"table",staticStyle:{"width":"100%"},attrs:{"empty-text":"No Data","data":_vm.filter(_vm.visibleUsers)},on:{"header-click":function($event){return _vm.toggleCourse($event.index)}}},[_c('el-table-column',{attrs:{"fixed":"","prop":"userID","label":_vm.visibleUsers.length ? 'ID' : '',"width":"50"}}),_c('el-table-column',{attrs:{"fixed":"","prop":"userName","label":_vm.visibleUsers.length ? 'Name' : '',"width":"150"}}),_c('el-table-column',{attrs:{"fixed":"","prop":"email","label":_vm.visibleUsers.length ? 'Email' : 'Users',"width":"250"}}),(_vm.visibleUsers.length)?_c('div',{staticClass:"div"},_vm._l((_vm.courses),function(course,index){return _c('el-table-column',{key:index,attrs:{"min-width":"250","label":course.name,"index":+index,"prop":("c_" + index)},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('progress-bar',{attrs:{"percentage":Math.ceil(slotProps.row[slotProps.column.property]) || 0}})]}}],null,true)},[_c('template',{staticClass:"header-slot",slot:"header"},[_vm._v(" "+_vm._s(course.name)+" "),_c('i',{class:_vm.showLabs[index]
                  ? !_vm.showLabs[_vm.showLabs.findIndex(function (item) { return item.id === +index; })]
                      .show
                    ? 'el-icon-arrow-down'
                    : 'el-icon-arrow-up'
                  : ''})]),(
              _vm.showLabs[_vm.showLabs.findIndex(function (item) { return item.id === +index; })].show
            )?_vm._l((course.labs),function(lab,index){return _c('el-table-column',{key:lab.index,attrs:{"width":"250","label":lab.name,"prop":("l_" + index)},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('progress-bar',{attrs:{"percentage":Math.ceil(props.row[props.column.property]) || 0}})]}}],null,true)})}):_vm._e()],2)}),1):_vm._e()],1)],_c('div',{staticClass:"table__pagination"},[_c('el-pagination',{attrs:{"page-sizes":[10, 20, 50, 100],"page-size":_vm.pageSize,"layout":"sizes, prev, pager, next","background":"","total":_vm.visibleUsers.filter(
          function (data) { return !this$1.search ||
            data.userName.toLowerCase().includes(this$1.search.toLowerCase()); }
        ).length},on:{"current-change":_vm.changePagination,"size-change":_vm.handleSizeChange}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
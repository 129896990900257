






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IGroup } from '@/models/users';

@Component({
  name: 'InfoGroup',
  components: {},
})
export default class extends Vue {
  @Prop({ required: true }) private group!: IGroup;
  private defaultProps = {
    children: 'labs',
    label: 'name',
    percentage: 'percentage',
  };
}

import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { IDashboardUser } from '@/models/users';
import { getDashboardData, getDashboardLabsInfo } from '@/api/users';
//import { users } from '@/fakeData/dashboard';

@Module({ dynamic: true, store, name: 'dashboard' })
class Dashboard extends VuexModule {
  users: IDashboardUser[] = [];
  courses = [];
  labsInfo = [];
  labsTitle = [];

  @Mutation
  private DASHBOARD_SET_USERS(payload) {
    this.users = payload.users;
    this.courses = payload.courses;
  }

  @Mutation
  private DASHBOARD_GET_LABS_INFO(payload) {
    this.labsInfo = payload.labsInfo;
    this.labsTitle = payload.labsTitle;
  }

  @Action
  public async GetDashboardUsers() {
    const response: any = await getDashboardData();

    if (!response.data) {
      response.data = [];
    }
    const users = response.data.usersInfo;
    const courses = response.data.courseTitle;

    this.DASHBOARD_SET_USERS({
      users: users,
      courses: response.data.courseTitle,
    });
  }

  @Action
  public async GetDashboardLabsInfo(courseID: number) {
    const response = await getDashboardLabsInfo({ courseID: +courseID });

    this.DASHBOARD_GET_LABS_INFO(response.data);
  }
}

export const DashboardModule = getModule(Dashboard);

import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import {IChallenge, ICourseInfo, ILabInfo} from '@/models/users';
//import { coursesList } from '@/fakeData/dashboard';
import {
  createCourse,
  deleteCourse,
  getCourses,
  getGroupsForCourse,
  getLabById,
  updateCourse,
  updateGroupsForCourse,
  updateGroupsForLab,
  getKubernetesVersions
} from '@/api/courses';
import {
  createLab,
  deleteLab,
  updateLab,
  getGroupsForLab,
  getChallengeByID,
  createChallenge,
  updateChallenge,
} from '@/api/labs';
import {GroupsModule} from './groupsState';

@Module({dynamic: true, store, name: 'courses'})
class Courses extends VuexModule {
  courses: ICourseInfo[] = [];
  selectedCourse: ICourseInfo | null = null;
  selectedLab: ILabInfo | null = null;
  groupsForCourse: any = {};
  groupsForLab: any = {};
  currentLab = {};
  checkedGroups: any = [];
  challenges: IChallenge | null = null;
  kubernetesVersions: any = {};

  @Mutation
  private SET_COURSES(courses: ICourseInfo[]) {
    this.courses = courses;
  }

  @Mutation
  private GROUPS_FOR_COURSES(object) {
    const m = {};
    object.forEach((el) => {
      m[el.groupID] = el.access;
    });

    this.checkedGroups = GroupsModule.groups.map((group) => {
      group.checked = m[group.id];
      return group;
    });
    this.groupsForCourse = object;
  }

  @Mutation
  private SET_SELECTED_COURSE(course: ICourseInfo | null) {
    this.selectedCourse = course;
  }

  @Mutation
  private SET_SELECTED_LAB(lab: ILabInfo | null) {
    this.selectedLab = lab;
  }

  @Mutation
  private CLEAR_SELECTED_LAB() {
    this.selectedLab = null;
  }

  @Mutation
  private DELETE_COURSE(payload: {index: number; course: ICourseInfo}) {
    this.courses.splice(payload.index, 1);
  }

  @Mutation
  private EDIT_COURSE(payload: {indexCourse: number; course: ICourseInfo}) {
    const newCourse = {...payload.course};
    this.courses.splice(payload.indexCourse, 1, newCourse);
  }

  @Mutation
  private CREATE_COURSE(course: ICourseInfo) {
    //course.id = this.courses.length;
    this.courses.push(course);
  }

  @Mutation
  private DELETE_LAB(payload: {indexCourse: number; indexLab: number}) {
    this.courses[payload.indexCourse].labs.splice(payload.indexLab, 1);
  }

  @Mutation
  private EDIT_LAB(payload: {
    indexCourse: number;
    lab: ILabInfo;
    indexLab: number;
  }) {
    const newLab = {...payload.lab};
    this.courses[payload.indexCourse].labs.splice(payload.indexLab, 1, newLab);
  }

  @Mutation
  private CREATE_LAB(payload: {lab: ILabInfo; index: number}) {
    if (!this.courses[payload.index].labs) {
      this.courses[payload.index].labs = [];
    }
    this.courses[payload.index].labs.push(payload.lab);
  }

  @Mutation
  private async UPDATE_GROUPS_FOR_COURSE(groups) {
    const response = await updateGroupsForCourse(groups);
  }

  @Mutation
  private async UPDATE_GROUPS_FOR_LAB(groups) {
    const response = await updateGroupsForLab(groups);
  }

  @Mutation
  private GET_GROUPS_FOR_LABS(lab) {
    //courses logic
    const m = {};
    lab.forEach((el) => {
      m[el.groupID] = el.access;
    });

    this.checkedGroups = GroupsModule.groups.map((group) => {
      group.checked = m[group.id];
      return group;
    });
    this.groupsForLab = lab;
  }

  @Mutation
  public GET_CHALLENGE(challenges) {
    this.challenges = challenges;
  }

  @Mutation
  public SET_KUBERNETES_VERSIONS(versions) {
    this.kubernetesVersions = versions;
  }

  @Mutation
  private CHANGE_CHALLENGE(challenges) {
    this.challenges = challenges;
  }

  @Action({rawError: true})
  public async GetChallenge(id) {
    const response = await getChallengeByID(id);
    this.GET_CHALLENGE(response.data ? response.data : null);
  }

  @Action
  public async GetKubernetesVersions() {
    const response = await getKubernetesVersions();

    if (response.data?.images) {
      this.SET_KUBERNETES_VERSIONS(response.data.images);
    }
  }

  @Action
  public async CreateChallenge(challenge: IChallenge) {
    const response = await createChallenge(challenge);
    this.CHANGE_CHALLENGE(response.data);
  }

  @Action
  public async UpdateChallenge(challenge: IChallenge) {
    const response = await updateChallenge(challenge);
    this.CHANGE_CHALLENGE(response.data);
  }

  @Action
  public async GetGroupsForLabs(labID) {
    const response = await getGroupsForLab({labID: labID});
    this.GET_GROUPS_FOR_LABS(response.data);

    return response.data;
  }

  @Action
  public UpdateGroupsForCourse(groups) {
    this.UPDATE_GROUPS_FOR_COURSE(groups);
  }

  @Action
  public UpdateGroupsForLab(groups) {
    this.UPDATE_GROUPS_FOR_LAB(groups);
  }

  @Action
  public CurrentLab(id) {
    getLabById(id).then((response) => this.SetSelectedLab(response.data));
    //this.SET_CURRENT_LAB(id);
  }
  @Action
  public async GetCourses() {
    const response = await getCourses();

    this.SET_COURSES(response.data);
  }

  @Action({rawError: true})
  public async SetSelectedLab(lab: ILabInfo | null) {
    this.SET_SELECTED_LAB(lab);
  }

  @Action({rawError: true})
  public async ClearSelectedLab() {
    this.CLEAR_SELECTED_LAB();
  }

  @Action
  public async SetSelectedCourse(course: ICourseInfo | null) {
    this.SET_SELECTED_COURSE(course);
  }

  @Action
  public async CreateCourse(course: ICourseInfo) {
    const response = await createCourse(course);
    this.CREATE_COURSE(response.data);
  }

  @Action
  public async EditCourse(course: ICourseInfo) {
    const indexCourse = this.courses.findIndex(
      (c: ICourseInfo) => c.id === course.id
    );
    const copyCourse = Object.assign({}, course);
    const response = await updateCourse(copyCourse);
    this.EDIT_COURSE({indexCourse: indexCourse, course: course});
  }

  @Action({rawError: true})
  public async EditLab(lab: ILabInfo) {
    const indexCourse = this.courses.findIndex(
      (c: ICourseInfo) => c.id === lab.courseID
    );

    this.CREATE_LAB({lab: lab, index: indexCourse});

    const indexLab = this.courses[indexCourse].labs.findIndex(
      (l: ILabInfo) => l.id === lab.id
    );
    const response = await updateLab(lab);

    this.EDIT_LAB({
      indexCourse: indexCourse,
      lab: lab,
      indexLab: indexLab,
    });
    this.GetCourses();
  }

  @Action
  public async DeleteCourse(course: ICourseInfo) {
    const index = this.courses.findIndex(
      (c: ICourseInfo) => c.id === course.id
    );
    const response = await deleteCourse({courseID: course.id});

    this.DELETE_COURSE({index: index, course: course});
  }

  @Action({rawError: true})
  public async CreateLab(lab: ILabInfo) {
    const indexCourse = this.courses.findIndex(
      (c: ICourseInfo) => c.id === lab.courseID
    );
    const response = await createLab(lab);
    this.CREATE_LAB({lab: lab, index: indexCourse});

    const indexLab = this.courses[indexCourse].labs.findIndex(
      (l: ILabInfo) => l.id === lab.id
    );

    lab = await response.data;

    this.EDIT_LAB({
      indexCourse: indexCourse,
      lab: lab,
      indexLab: indexLab,
    });
  }

  @Action({rawError: true})
  public async DeleteLab(lab: ILabInfo) {
    const indexCourse = this.courses.findIndex(
      (c: ICourseInfo) => c.id === lab.courseID
    );

    const indexLab = this.courses[indexCourse].labs.findIndex(
      (l: ILabInfo) => l.id === lab.id
    );

    const response = await deleteLab({labID: lab.id});

    this.DELETE_LAB({indexCourse: indexCourse, indexLab: indexLab});
  }

  @Action
  public async GetGroupsForCourse(id: {courseID: number}) {
    const response = await getGroupsForCourse(id);
    this.GROUPS_FOR_COURSES(response.data);

    return response.data;
  }
}

export const CoursesModule = getModule(Courses);

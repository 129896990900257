












import { DialogModule } from '@/store/modules/dialogState';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AddedLab',
  components: {}
})
export default class extends Vue {
  get showLabAdded() {
    return DialogModule.showNewLabAdded;
  }

  get labName() {
    return DialogModule.newLabModel.name;
  }

  private closeHandler() {
    DialogModule.toggleNewLabAdded();
  }
}













import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'Breadcrumbs',
})
export default class extends Vue {
  private pages: {url: string; title: string | null | undefined}[] = [{
    url: '/',
    title: 'Home'
  }]
  mounted() {
    if (this.$router.currentRoute.meta.parent) {
      this.pages.push(this.$router.currentRoute.meta.parent);
    }
    if (this.$router.currentRoute.path !== '/admin/dashboard' && this.$router.currentRoute.path !== '/user/dashboard') {
      this.pages.push(
          {
            title: this.$router.currentRoute.name,
            url: this.$router.currentRoute.path
          }
      )
    }

  }
}







































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { ICourseInfo } from '@/models/users';
import { DialogModule } from '@/store/modules/dialogState';
import { CoursesModule } from '@/store/modules/coursesState';

@Component({
  name: 'EditCourse',
  components: {},
})
export default class extends Vue {
  @Prop({ required: true }) private courseForm!: ICourseInfo;
  @Prop({ required: true }) private editing!: boolean;

  validForm = false;
  private loading = false;

  course: ICourseInfo | null = {
    name: '',
    description: '',
    id: 0,
    labs: [],
    accessGroups: null,
    image: ''
  };

  private validateField(rule: string, value: string, callback: Function) {
    if (!value) {
      return;
    }
    if (value.length < 6) {
      callback(new Error('The field can not be less than 6 characters'));
    } else if (value.length > 256) {
      callback(new Error('The field can not be more than 256 characters'));
    } else {
      callback();
    }
  }

  private validateNameCourse(rule: string, value: string, callback: Function) {
    if (!value) {
      return;
    }
    if (value.trim().length === 0) {
      callback(new Error('The field can not be empty'));
    }  else if (value.length > 256) {
      callback(new Error('The field can not be more than 256 characters'));
    }else {
      callback();
    }
  }

  private rules = {
    name: [{ validator: this.validateNameCourse, trigger: 'blur' }],
    description: [{ validator: this.validateField, trigger: 'blur' }],
  };

  private save() {
    (this.$refs.courseForm as ElForm).validate(async (valid: boolean) => {
      if (valid && this.course) {
        try {
          this.loading = true;
          if (this.editing) {
            await CoursesModule.EditCourse(this.course);
          } else {
            await CoursesModule.CreateCourse(this.course);
            await this.$nextTick();
            await DialogModule.toggleNewCourseAdded(this.course);
          }
        } finally {
          this.loading = false;
          this.closeDialog();
        }
      } else {
        return false;
      }
    });
  }

  private deleteCourse() {
    this.$confirm(
      'Are you sure you want to delete this course?',
      'Delete Course',
      {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'error',
      }
    ).then(() => {
      CoursesModule.DeleteCourse(this.courseForm);
      DialogModule.toggleEdit();
    });
  }

  mounted() {
    this.course = this.courseForm || {};
  }

  private closeDialog() {
    DialogModule.closeCreate();
    CoursesModule.SetSelectedCourse(null);
  }

  convertingToBase64(file) {
    const fileSelect = file.raw;
    const fileReader = new FileReader();

    fileReader.onload = ((FileLoadEvent) => {
      const srcData = FileLoadEvent.target.result;
      if (this.course) {
        this.course.image = srcData;
        this.validForm = true;
      }
    }).bind(this);

    fileReader.readAsDataURL(fileSelect);
  }

  clearDropZone(file) {
    file.raw = null;
  }

  @Watch('course', { deep: true })
  private validateForm() {
    (this.$refs.courseForm as ElForm).validate((valid: boolean) => {
      this.validForm = valid;
    });
  }
}

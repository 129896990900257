import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { IGroup } from '@/models/users';
import { dynamicSort } from '@/utils/filters';
import {
  createGroup,
  deleteGroup,
  editGroup,
  getGroups,
  getGroupInfo,
} from '@/api/groups';

@Module({ dynamic: true, store, name: 'groups' })
class Groups extends VuexModule {
  groups: IGroup[] = [];
  showEdit = false;
  showCreate = false;
  selectedGroup: IGroup | null = null;

  @Mutation
  private DELETE_GROUP(payload: { index: number; group: IGroup }) {
    this.groups.splice(payload.index, 1);
  }

  @Mutation
  private EDIT_GROUP(payload: { index: number; group: IGroup }) {
    this.groups[payload.index] = payload.group;
  }

  @Mutation
  private CREATE_GROUP(group: IGroup) {
    this.groups.push(group);
  }

  @Mutation
  private SET_GROUPS(courses: IGroup[]) {
    this.groups = courses;
  }

  @Mutation
  private SET_EDITED_GROUP(group: IGroup) {
    this.selectedGroup = group;
  }

  @Action
  public async GetGroups() {
    try {
      const response = await getGroups();
      if (response && response.data) {
        const groups = response.data;
        this.SET_GROUPS(groups);
      }
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async CreateGroup(group: IGroup) {
    const groups = { name: group.name, description: group.description };
    try {
      const response = await createGroup(groups);
      if (response && response.data) {
        const groupResponse = response.data;
        this.CREATE_GROUP(groupResponse);
      }
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async EditGroup(group: IGroup) {
    const groupIndex = this.groups.findIndex((u: IGroup) => u.id === group.id);
    try {
      const response = await editGroup(group);
      if (response && response.data && response.data === group.id) {
        this.EDIT_GROUP({ index: groupIndex, group: group });
      }
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async DeleteGroup(group: IGroup) {
    const groupIndex = this.groups.findIndex((g: IGroup) => g.id === group.id);
    try {
      const response = await deleteGroup(group.id);
      if (response && response.data) {
        this.DELETE_GROUP({ index: groupIndex, group: group });
      }
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public sortGroups(data: { prop: string; order: string | null }) {
    let order = 1;
    if (data.order === null) {
      this.groups.sort(dynamicSort('id', 1));
    } else {
      order = data.order === 'descending' ? -1 : 1;
      this.groups.sort(dynamicSort(data.prop, order));
    }
  }

  @Action
  public async GetGroupInfo(groupID: number) {
    const group = { groupID: groupID };
    try {
      const response = await getGroupInfo(group);
      this.SET_EDITED_GROUP(response.data);
    } catch (e) {
      console.log(e);
    }
  }
}

export const GroupsModule = getModule(Groups);



















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { IGroup } from '@/models/users';
import { DialogModule } from '@/store/modules/dialogState';
import { GroupsModule } from '@/store/modules/groupsState';

@Component({
  name: 'EditGroup',
  components: {},
})
export default class extends Vue {
  @Prop({ required: true }) private groupForm!: IGroup;

  private loading = false;
  private validForm = false;

  get editing() {
    return DialogModule.editing;
  }

  private validateUsername(rule: string, value: string, callback: Function) {
    if (value.trim().length < 6) {
      callback(new Error('The username can not be less than 6 characters'));
    } else {
      callback();
    }
  }

  private validateDescription(rule: string, value: string, callback: Function) {
    if (value.length < 6) {
      callback(new Error('The field can not be less than 6 characters'));
    }  else if (value.length > 256) {
      callback(new Error('The field can not be more than 256 characters'));
    }  else {
      callback();
    }
  }

  private groupRules = {
    name: [{ validator: this.validateUsername, trigger: 'blur' }],
    description: [{ validator: this.validateDescription, trigger: 'blur' }],
  };

  private save() {
    (this.$refs.groupForm as ElForm).validate(async (valid: boolean) => {
      if (valid) {
        try {
          this.loading = true;
          if (this.editing) {
            await GroupsModule.EditGroup(this.groupForm);
          } else {
            await GroupsModule.CreateGroup(this.groupForm);
            await this.$nextTick();
            await DialogModule.toggleNewGroupAdded(this.groupForm);
          }
        } finally {
          this.closeDialog();
          this.loading = false;
        }
      } else {
        return false;
      }
    });
  }

  private closeDialog() {
    DialogModule.closeCreate();
  }

  @Watch('groupForm', { deep: true })
  private validateForm() {
    (this.$refs.groupForm as ElForm).validate((valid: boolean) => {
      this.validForm = valid;
    });
  }
}

















































































































import { Component, Vue } from 'vue-property-decorator';
import { IGroup } from '@/models/users';
import ProgressBar from '@/components/progressBar/Index.vue';
import Breadcrumbs from '@/components/breadcrumbs/Index.vue';
import EditGroup from '@/components/dialogs/editGroup/Index.vue';
import InfoGroup from '@/components/dialogs/infoGroup/Index.vue';
import { DialogModule } from '@/store/modules/dialogState';
import { GroupsModule } from '@/store/modules/groupsState';
import { BLANK_GROUP } from '@/models';

@Component({
  name: 'Groups',
  components: {
    ProgressBar,
    Breadcrumbs,
    EditGroup,
    InfoGroup
  }
})
export default class extends Vue {
  private search = '';
  private currentPage = 1;
  private editedGroup: IGroup | null = null;
  private groups: IGroup[] = [];
  private sort: { prop: string; order: string } = {
    prop: 'id',
    order: 'ascending'
  };
  private selectedGroup: IGroup | null = null;
  private group: IGroup = {
    createdAt: 'string',
    description: 'string',
    id: 2,
    name: 'string',
    updatedAt: 'string',
    checked: true
  };
  private showInfo = false;

  private groupsLoading = true;

  get showEditDialog() {
    return DialogModule.showEdit;
  }
  set showEditDialog(data: boolean) {
    DialogModule.toggleEdit();
  }

  get editing() {
    return DialogModule.editing;
  }

  get showCreateDialog() {
    return DialogModule.showCreate;
  }
  set showCreateDialog(data: boolean) {
    DialogModule.toggleCreate();
  }

  get showNewGroupAdded() {
    return DialogModule.showNewGroupAdded;
  }

  get addedGroupName() {
    return DialogModule.newGroupModel.name;
  }

  mounted() {
    GroupsModule.GetGroups()
      .then(() => {
        this.groups = GroupsModule.groups;
      })
      .finally(() => {
        this.groupsLoading = false;
      });
  }

  public changePagination(page: number) {
    this.currentPage = page;
  }

  private filter(groups: IGroup[]): IGroup[] {
    return groups
      .filter(
        (data: IGroup) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      )
      .slice((this.currentPage - 1) * 10, this.currentPage * 10);
  }

  private deleteGroup(group: IGroup) {
    this.$confirm(
      'Are you sure you want to delete this group?',
      'Delete Group',
      {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'error'
      }
    ).then(() => {
      GroupsModule.DeleteGroup(group);
    });
  }

  private showDetails(group: IGroup) {
    this.group = group;
    GroupsModule.GetGroupInfo(group.id).then(() => {
      this.showInfo = true;
      this.selectedGroup = GroupsModule.selectedGroup;
    });
  }

  private addGroup() {
    this.editedGroup = { ...BLANK_GROUP() };
    DialogModule.toggleCreate();
  }

  private closeDialog() {
    DialogModule.closeCreate();
    // UsersModule.sortGroups(this.sort);
  }

  private closeGroupAddedDialogHandler() {
    DialogModule.toggleNewGroupAdded();
  }

  private getDialogTitle(): string {
    let title = '';
    title = this.editing ? 'Edit Group' : 'Create Group';
    return title;
  }

  editGroup(group: IGroup) {
    this.editedGroup = { ...group };
    DialogModule.toggleEdit();
  }

  private changeSort(e: { prop: string; order: string }) {
    this.sort = { prop: e.prop, order: e.order };
    GroupsModule.sortGroups({ prop: e.prop, order: e.order });
  }

  private groupsNameSort(firstEl, secondEl) {
    const nameA = firstEl.name.replace(/\s/g, '').toLowerCase();
    const nameB = secondEl.name.replace(/\s/g, '').toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
}














import { DialogModule } from '@/store/modules/dialogState';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AddedCourse',
  components: {}
})
export default class extends Vue {
  get showCourseAdded() {
    return DialogModule.showNewCourseAdded;
  }

  get courseName() {
    return DialogModule.newCourseModel.name;
  }

  private closeHandler() {
    DialogModule.toggleNewCourseAdded();
  }
}
